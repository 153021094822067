export function getState(state) {
  return state[0];
}

export function setState(state, value) {
  return state[1](value);
}

export function updateState(state, value) {
  return state[1]({
    ...state[0],
    ...value,
  });
}
