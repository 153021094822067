/**
 * Gets the specific setting from a section, if it doesnt exist, returns an
 * an empty setting section including an empty options object.
 * @param {String} setting the setting name
 * @param {Array} sections the setting section list, e.g. general or theme
 * @returns {Setting}|undefined
 */
export default (setting, sections) => {
  const section = sections.find(
    ({ __typename }) => 'API_' + setting == __typename
  );

  console.log('setting', setting);
  console.log('section', section);

  return section;
};
