import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/styles';

/**
 * Used to parse raw HTML into React components, each tag corresponds
 * to one function with the same name as the tag, this function takes
 * props as an argument which contains all the attributes of the tag.
 * @param {Object} componentsMap object to override default attributes
 */
export default function makeContentComponentsMap(componentsMap) {
  /**
   * Make a link depending on if its a social tag link, i.e. a hashtag
   * or a mention based on data attributes, if not, make a normal link.
   * @param {React.Props} props
   */
  function makeLink(props) {
    const socialTagName = props['data-tag-name'];
    const socialTagSymbol = props['data-tag-symbol'];
    const socialTag = `${socialTagSymbol}${socialTagName}`;
    const preventDefault = e => e.preventDefault();

    if (socialTagName) {
      if (socialTagSymbol === '#') {
        return (
          <ContentLink color="inherit" href="#" onClick={preventDefault}>
            {socialTag}
          </ContentLink>
        );
      } else if (socialTagSymbol === '@') {
        return (
          <ContentLink
            href={`https://www.instagram.com/${socialTagName}`}
            target="blank"
            color="inherit"
          >
            {socialTag}
          </ContentLink>
        );
      }
    } else {
      return <ContentLink color="primary" {...props} />;
    }
  }

  /**
   * Make a paragraph with the body1 variant
   * @param {React.Props} props
   */
  function makeParagraph(props) {
    return <Typography paragraph variant="body1" {...props} />;
  }

  function makeFigure(props) {
    return <ContentFigure>{props.children}</ContentFigure>;
  }

  function makeImage(props) {
    return (
      <ContentImage
        alt={props.alt}
        srcset={props.srcset}
        sizes={props.sizes}
        src={props.src}
      />
    );
  }

  /**
   * Make an iFrame with a certain aspect ratio
   * @param {React.Props} props
   */
  function makeIframe(props) {
    return (
      <ContentIframeContainer>
        <ContentIframe {...props} allowfullscreen="true"></ContentIframe>
      </ContentIframeContainer>
    );
  }

  return {
    p: makeParagraph,
    figure: makeFigure,
    img: makeImage,
    a: makeLink,
    iframe: makeIframe,
    ...componentsMap,
  };
}

const ContentImage = styled('img')(({ theme }) => ({
  maxWidth: '100%',
  borderRadius: theme.spacing(1),
  display: 'block',
}));

const ContentFigure = styled('figure')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(2)}px`,
  padding: `${theme.spacing(2)}px 0`,
}));

const ContentIframeContainer = styled('div')(({ theme }) => ({
  // marginTop: theme.spacing(2),
  position: 'relative',
  // height: 0,
  // paddingBottom: '56.25%',
  '& > iframe': {
    display: 'block',
    border: 'none',
    width: '100%',
  },
}));

const ContentIframe = styled('iframe')(({ theme }) => ({
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
}));

const ContentLink = styled('a')(({ theme, color }) => ({
  textDecoration: 'none',
  fontWeight: 500,
  ...(color === 'primary' && {
    color: theme.palette.primary.main,
  }),
  ...(color === 'inherit' && {
    color: 'inherit',
  }),
}));
