import React, { useEffect, useState, useLayoutEffect } from 'react';
import R from 'ramda';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider, styled, useTheme } from '@material-ui/styles';
import LandingPage from '../LandingPage/LandingPage';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import AppBar from '../AppBar/AppBar';
import Menu from '../Menu/Menu';
// import { StickyContainer, Sticky } from 'react-sticky';
import Footer from '../Footer/Footer';
import Sticky from 'react-stickynode';
import getSetting from '../../util/getSetting';
import { getState, setState } from '../../util/stateLens';

function Layout(props) {
  const browserUiBarsHeight$ = useState(0);
  const theme = useTheme();
  const screenSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  const siteInfoSetting = getSetting('SiteInfoSetting', props.settings.general);
  const logoSetting = getSetting('LogoSetting', props.settings.theme);
  const coverMediaSetting = getSetting('CoverSetting', props.settings.theme);
  const profileColorsSetting = getSetting(
    'ProfileColorsSetting',
    props.settings.theme
  );

  console.log('props.settings.theme :>> ', props.settings.theme);

  useLayoutEffect(() => {
    console.log('TESTING');
    const browserUiBarsHeight = screenSmallAndUp ? 80 : 56;

    setState(browserUiBarsHeight$, browserUiBarsHeight);
  });

  /**
   * Overrides the theme with user specific content
   * @note this would preferably be done in the main theme, however, it
   *       doesn't seem possible to fetch data from TopLayout plugin(?)
   */
  // const appTheme = createMuiTheme({
  //   ...theme,
  // palette: {
  //   ...theme.palette,
  //   primary: {
  //     ...theme.palette.primary,
  //     //profileColorsSetting
  //     main:
  //       // ? profileColorsSetting.options.primary.hex:
  //       theme.palette.primary.main,
  //   },
  // },
  // });

  // return (

  // return (
  return (
    <Root>
      <Helmet>
        <title>
          {props.title}{' '}
          {siteInfoSetting?.options.title
            ? ` | ${siteInfoSetting?.options.title}`
            : ''}
        </title>
      </Helmet>
      {/* <ThemeProvider theme={appTheme}> */}
      {coverMediaSetting?.options.media.desktop && (
        <LandingPage
          variant="center"
          coverMedia={coverMediaSetting.options.media.desktop.sizes.large}
          browserUiBarsHeight={getState(browserUiBarsHeight$)}
        />
      )}
      <div id="anchor-header-main" />
      <Inner
        browserUiBarsHeight={getState(browserUiBarsHeight$)}
        hasCover={coverMediaSetting?.isEnabled}
      >
        <Sticky innerZ={100} top={0}>
          <Header id="header">
            {/* This is to trigger the sticky component when scrolled to programatically */}
            <div style={{ height: 1 }} />
            <div id="anchor-header" />
            {screenSmallAndUp && (
              <AppBar logoUrl={logoSetting?.options.image.sizes.medium.url} />
            )}
            <Menu location={props.location} />
          </Header>
        </Sticky>
        <Main>{props.children}</Main>
        {!props.hideFooter && (
          <Footer
            siteTitle={siteInfoSetting?.options.title}
            accounts={props.accounts}
          />
        )}
      </Inner>
      {/* </ThemeProvider> */}
    </Root>
  );
}

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  settings: PropTypes.object.isRequired,
  accounts: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  hideFooter: PropTypes.bool,
};

const Root = styled('div')(({ theme }) => ({
  background: 'transparent',
}));

const Inner = styled('div')(({ theme, browserUiBarsHeight, hasCover }) => ({
  marginTop: `calc(100vh - ${browserUiBarsHeight}px)`,
  background: theme.palette.common.white,
  position: 'relative',
}));

const Header = styled('header')(({ theme, isSticky, topOffset }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottomWidth: '1px',
  borderBottomStyle: 'solid',
  borderBottomColor: '#efefef',
  background: `rgba(255,255,255, 0.97)`,
  zIndex: theme.zIndex.appBar,
  ...(isSticky && {
    borderBottomWidth: 0,
  }),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    padding: `0 ${theme.spacing(6)}px`,
    borderBottom: 'none',
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(8)}px`,
  },
}));

const Main = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    // padding: `${theme.spacing.unit * 7}px 0`, // margin: '0 auto',
    padding: '4vw 0',
  },
  [theme.breakpoints.up('md')]: {
    // padding: `${theme.spacing.unit * 7}px 0`, // margin: '0 auto',
    // padding: '4vw 0',
    padding: '6vw 0',
  },
  [theme.breakpoints.up('lg')]: {
    // padding: `${theme.spacing.unit * 11}px 0`,
    // margin: '0 auto',
    // padding: '6vw 0',
  },
  [theme.breakpoints.up('xl')]: {
    // padding: `${theme.spacing(13)}px 0`,
    // margin: '0 auto',
  },
}));

export default Layout;
