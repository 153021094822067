import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';

import { Link } from 'gatsby';
import MuiToolbar from '@material-ui/core/Toolbar';
import MuiAppBar from '@material-ui/core/AppBar';

function AppBar(props) {
  return (
    <Container>
      <MuiAppBar position="static" color="white" elevation="0">
        <Toolbar>
          <Link href="/">
            <Logo src={props.logoUrl} alt="Logo" />
          </Link>
        </Toolbar>
      </MuiAppBar>
    </Container>
  );
}

AppBar.propTypes = {
  logoUrl: PropTypes.string.isRequired,
};

const Container = styled('div')({
  flexGrow: 1,
});

const Toolbar = styled(MuiToolbar)(({ theme }) => ({
  // minHeight: 88,
  minHeight: theme.spacing(4),
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'center',
    minHeight: 80,
    paddingLeft: 0,
  },
}));

const Logo = styled('img')(({ theme }) => ({
  margin: '0 auto',
  display: 'block',
  top: 0,
  [theme.breakpoints.up('sm')]: {
    height: 24,
  },
}));

export default AppBar;
