import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { getState, setState } from '../../util/stateLens';
import scrolls from 'scrolls.js';
import { styled, useTheme } from '@material-ui/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import MuiTabs from '@material-ui/core/Tabs';
import MuiTab from '@material-ui/core/Tab';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) =>
  createStyles({
    tab: {
      textTransform: 'capitalize',
      [theme.breakpoints.up('sm')]: {
        minHeight: 80,
        minWidth: 80,
      },
      [theme.breakpoints.up('lg')]: {
        minWidth: 0,
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
  })
);

function Menu(props) {
  const theme = useTheme();
  const classes = useStyles();
  const didNavigateToPage$ = useState(false);
  const path$ = useState(props.location.pathname);
  const screenSmallAndUp = useMediaQuery(theme.breakpoints.up('sm'));

  function handleTabChange(_event, path) {
    setState(path$, path);
    scrollToMain();
  }

  function scrollToMain() {
    scrolls('#anchor-header', {
      durationOfMovement: 500, // Defaults to 1000, or 1 second.
    });
  }

  const Tab = React.forwardRef(function Link(props, ref) {
    return <MuiTab component={GatsbyLink} ref={ref} {...props} />;
  });

  return (
    <Container style={props.style} isSticky={props.isSticky}>
      <Inner>
        <AppBar position="static" elevation="0">
          <Tabs
            value={getState(path$)}
            onChange={handleTabChange}
            indicatorColor="transparent"
            // indicatorColor={`${screenSmallAndUp ? 'paper' : 'primary'}`}
            textColor="primary"
            // variant={`${screenSmallAndUp ? "" : "scrollable"}`}
            variant={`${screenSmallAndUp ? '' : 'fullWidth'}`}
          >
            <Tab
              label="Flöde"
              value="/"
              to="/"
              classes={{
                root: classes.tab,
              }}
            />
            <Tab
              label="About"
              value="/om"
              to="/om"
              classes={{
                root: classes.tab,
              }}
            />
          </Tabs>
        </AppBar>
      </Inner>
    </Container>
  );
}

Menu.propTypes = {
  location: PropTypes.string.isRequired,
  isSticky: PropTypes.bool.isRequired,
};

const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  background: theme.palette.common.white,
  width: '100%',
  touchAction: 'none',
}));

const Inner = styled('div')(({ theme }) => ({
  // padding: theme.spacing(1),
  overflow: 'hidden',
}));

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  background: 'transparent',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'flex-end',
  },
}));

const Tabs = styled(MuiTabs)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  [theme.breakpoints.up('sm')]: {
    minHeight: theme.spacing(10),
    marginRight: -theme.spacing(3),
  },
}));

export default Menu;
