import React, { useEffect, useState } from 'react';
import scrolls from 'scrolls.js';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import { setState, getState } from '../../util/stateLens';

function LandingPage(props) {
  function scrollToMain() {
    scrolls('#anchor-header', {
      durationOfMovement: 500, // Defaults to 1000, or 1 second.
    });
  }

  /**
   * Selects the layout based on the layout prop
   */
  function Layout() {
    // if (props.layout == 'center') {
    return (
      <div
        // coverMedia={props.coverMedia}
        style={{
          backgroundImage: `url(${props.coverMedia.url})`,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundImage: `url(${coverMedia.url})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'none',
          backgroundSize: 'cover',
          height: '100%',
          // [theme.breakpoints.up('sm')]: {
          //   backgroundAttachment: 'fixed',
          // },
        }}
      />
    );
    // }
  }

  return (
    <Container
      id="cover"
      browserUiBarsHeight={props.browserUiBarsHeight}
      onTouchStart={scrollToMain}
    >
      <Layout />
    </Container>
  );
}

LandingPage.propTypes = {
  coverMedia: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
};

const Container = styled('div')(({ browserUiBarsHeight }) => ({
  width: '100%',
  height: `calc(100vh - ${browserUiBarsHeight}px)`,
  position: 'fixed',
  top: 0,
  touchAction: 'none',
}));

const CenteredLayout = styled('div')(({ theme, coverMedia }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // backgroundImage: `url(${coverMedia.url})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'none',
  backgroundSize: 'cover',
  height: '100%',
  [theme.breakpoints.up('sm')]: {
    backgroundAttachment: 'fixed',
  },
}));

export default LandingPage;
