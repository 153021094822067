import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';
import { Typography, Box } from '@material-ui/core';
import * as R from 'ramda';
import SocialIcon from '../Icons/SocialIcon/SocialIcon';

function Footer(props) {
  const date = new Date();
  const year = date.getFullYear();

  function getFirstEnabledAccountIndex(origin) {
    const isOrigin = R.propEq('origin', origin);
    const isEnabled = R.propEq('isEnabled', true);
    const index = R.findIndex(R.allPass([isOrigin, isEnabled]), props.accounts);
    return index;
  }

  function goToRemoteAccountSocialMedia(origin) {
    const accountIndex = getFirstEnabledAccountIndex(origin);
    window.location.href = props.accounts[accountIndex].linkUrl;
  }

  function socialMediaExists(origin) {
    const accountIndex = getFirstEnabledAccountIndex(origin);
    return props.accounts[accountIndex];
  }

  function isAnyRemoteAccountsEnabled() {
    const index = R.findIndex(R.propEq('isEnabled', true), props.accounts);

    return !!index;
  }

  function getIconMargin(variant) {
    switch (variant) {
      case 'fb':
        return 0.5;
      case 'ig':
        return 1;
    }
  }

  function getVerboseOrigin(variant) {
    switch (variant) {
      case 'fb':
        return 'Facebook';
      case 'ig':
        return 'Instagram';
    }
  }

  function SocialMediaLink(props) {
    const iconMargin = getIconMargin(props.variant);
    const verboseOrigin = getVerboseOrigin(props.variant);

    return (
      <Typography
        display="inline"
        cursor="pointer"
        ml={2}
        mr={2}
        onClick={() => goToRemoteAccountSocialMedia(props.variant)}
        component="span"
        color="textSecondary"
        variant="body1"
      >
        <Box
          pl={2}
          pr={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SocialIcon variant={props.variant} />
          <Box display="inline" ml={iconMargin}>
            {verboseOrigin}
          </Box>
        </Box>
      </Typography>
    );
  }

  SocialMediaLink.propTypes = {
    variant: PropTypes.string.isRequired,
  };

  return (
    <Container>
      {isAnyRemoteAccountsEnabled() && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          marginBottom={2}
          color="palette.primary.main"
        >
          {socialMediaExists('fb') && <SocialMediaLink variant="fb" />}
          {socialMediaExists('ig') && <SocialMediaLink variant="ig" />}
        </Box>
      )}
      <div>
        <Typography variant="body2" color="textSecondary">
          <Typography display="inlineBlock" component="span">
            {props.siteTitle} © {year}
          </Typography>
          {' - '}
          <Typography display="inlineBlock" component="span">
            All Rights Reserved
          </Typography>
        </Typography>
      </div>
    </Container>
  );
}

Footer.propTypes = {
  siteTitle: PropTypes.string.isRequired,
  accounts: PropTypes.array.isRequired,
};

const Container = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  width: '100%',
  padding: `${theme.spacing(4)}px 0`,
  backgroundColor: 'white',
  fontSize: '14px',
  textAlign: 'center',
  fontFamily: 'Arial',
}));

export default Footer;
